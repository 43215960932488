import { useEffect, useState } from 'react';
import { Box, Center, ChakraProps, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { H3 } from 'components/Typography';

import LoaderIcon from './LoaderInner';

const phrases = [
  'Connecting to the database...',
  'Retrieving data...',
  'Analyzing information...',
  'Compiling results...',
  'Almost there...',
];

const AnimatedPhrases = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 10_000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box pt={4} w={'100%'} textAlign={'center'}>
      <motion.div
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Text>{phrases[index]}</Text>
      </motion.div>
    </Box>
  );
};

function Loader({
  animate = true,
  longLoader,
  ...props
}: ChakraProps & { animate?: boolean; longLoader?: boolean }) {
  return (
    <Center data-testid={'loader'} width="100%" height="100vh" {...props}>
      <Flex direction="column" alignItems="center">
        <Flex width={'150px'} height={'150px'}>
          <LoaderIcon animate={animate} />
        </Flex>

        {longLoader ? (
          <Box pt={4}>
            <H3>We're scanning the database, this may take a few minutes</H3>
            <AnimatedPhrases />
          </Box>
        ) : (
          <Text pt={4} textStyle="p2" color="text3">
            loading
          </Text>
        )}
      </Flex>
    </Center>
  );
}

export default Loader;
